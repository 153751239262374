@import 'assets/softco_base_primeng_theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';
@import '~primeflex/primeflex.css';
@import '~@fortawesome/fontawesome-free/css/all.css';


html {
    font-size: 14px;
}

body {
    font-family: var(--font-family);
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    padding: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s, color 0s 600000s;;
}

.alert-danger {
    color: var(--red-500);
}

.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.label-required:after {
    content: " *";
}
.p-dialog .p-dialog-header {
    background: var(--surface-ground);
    font-size: 1.5rem;
}
.form-group div .p-dropdown {
  min-width: 100%;
  display: flex;
  max-width: 0px;
}
.onboarding-form {
  min-height: 477px;
}
.onboarding-form-grid {
    min-height: 625px;
}
.p-button:disabled {
    opacity: 0.6;
}
.button-label-lg {
    font-size: 1.1rem;
    line-height: 1.6rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-overlay:has(.p-dropdown-panel) {
  max-width: 0;
}
.p-column-filter-row {
    margin-right: 3rem;
}
.form-control.p-component:disabled, .form-control.p-dropdown.p-disabled {
    opacity: 1;
    background: #f5f7fb;
    border: 0px;
}
.form-control.p-component:disabled, .form-control.p-multiselect.p-disabled {
    opacity: 1;
    background: #f5f7fb;
    border: 0px;
}
p-columnfilterformelement>input {
    height: 2rem;
}
p-columnfilterformelement>p-dropdown {
    height: 2rem !important;
    display: flex;
}

p-columnfilterformelement>p-dropdown .p-dropdown {
    width:100%;
}
p-columnfilterformelement>p-dropdown .p-dropdown-clear-icon {
    display: none;
}
p-columnfilterformelement>p-dropdown .p-dropdown-label {
    align-items: center;
    display: flex !important;
}
p-columnfilterformelement>p-inputnumber{
    height: 2rem;
}
.p-column-filter-row p-columnfilterformelement {
    width:100% !important;
}
.p-datatable-thead>tr>th {
    border: none !important;
    padding: 0.5rem !important;
}
.text-break-word {
   word-break: break-word;
}
.align-items-start {
   align-items: start;
}
.profileMenu {
   top:50px !important;
}
body.p-overflow-hidden {
    overflow: auto !important;
}

.py-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

div.col-details-op-button .p-button {
    height: 1.5rem;
    border-radius: 1.5625rem;
    padding: 0.75rem 1rem;
}
div.col-details-op-button .p-button span {
    color: #FBFCFF;
    font-size: 0.6875rem;
    font-weight: 600;
    line-height: 1.25rem;
}

.columnDetailsContent.p-overlaypanel {
    margin-top: 0px;
}
.columnDetailsContent .p-overlaypanel-content {
    padding: 0rem;
}
.columnDetailsContent.p-overlaypanel:after, .columnDetailsContent.p-overlaypanel:before {
    display: none;
}
.columnDetailsContent.p-overlaypanel .p-overlaypanel-close:enabled:hover, .columnDetailsContent.p-overlaypanel .p-overlaypanel-close:enabled {
    background: none;
    color: var(--Brand-text-color, #081159);
    top: 0rem;
    right: 0.25rem;
}
.columnDetailsContent .col-details-op-header-row {
    min-width: 17rem;
    margin-bottom: 0.25rem;
    border-bottom: #D0D3D9 solid 1px;
}
.columnDetailsContent .col-details-op-header {
    color: var(--Brand-text-color, #081159);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 150%;
}
.columnDetailsContent .col-details-op-line {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.85rem;
    margin-left: 1rem;
    margin-right: 0.75rem;
    max-width: 17rem;
}
.columnDetailsContent .op-line-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
}
.columnDetailsContent .op-line-tag {
    height: 1.5rem;
    border-radius: 1.5625rem;
    font-size: 0.65625rem;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    width: 4.25rem;
}

.grid-action-item .p-disabled {
    background: var(--surface-0);
}
.grid-action-item .p-disabled .p-menuitem-text {
    color: var(--gray-400);
}

.p-progress-spinner-circle {
    stroke: var(--Brand-text-color, #081159) !important;
}
